import React from 'react';
import '../../App.css';
import './MainHeroSection.css';

function HeroSection() {
    return (
        <div className='hero-wrap'>
            <div className='hero-container'>
                <div className='text-content'>
                    <h1>THE LAB STUDIOS | MIAMI</h1>
                    <p>Finding your true sound with industry legends—The Lab has been home to icons like Daddy Yankee, Don Omar, Wisin & Yandel, and many more.</p>                </div>
            </div>
        </div>
    );
}


export default HeroSection;