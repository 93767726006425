import React from 'react';
import Biography from '../containers/Biography/Biography';
import Footer from '../containers/Footer/Footer';
  
const Bio = () => {
  return (
    <div>
      <Biography />
      <Footer />
    </div>
  );
};
  
export default Bio;