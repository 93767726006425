import React from "react";
import "./Services.css";
import { FaMicrophone, FaHeadphones, FaCompactDisc, FaVolumeUp } from "react-icons/fa"; // Example icons

function Services() {
  const services = [
    {
      title: "Creative Production",
      description: "Have our team of producers and composers craft you exactly the beat and the song your looking for customized to you or come and listen to our catalogue of ideas, beats and music.",
      icon: <FaCompactDisc />, // Use an icon of choice
      link: "#",
    },
    {
      title: "Recording",
      description: "As a recording studio The Lab has welcomed many of the most important names in the industry  including: Daddy Yankee, Tego Calderon, Don Omar, Wisin & Yandel, Ricky Martin, Nicky Jam, Gente de Zona, Peso Pluma, and many others.",
      icon: <FaMicrophone />,
      link: "#",
    },
    {
      title: "Mix & Master",
      description: "From music recordings, post-editing, and vocal productions to sound design and music and film mixing, our studio offers it all for everyone from upcoming rising stars to vocal creatives who’ve hit the global stage.",
      icon: <FaVolumeUp />,
      link: "#",
    },
    {
      title: "Dolby Atmos",
      description: "Atmos has reinvented how entertainment is created and experienced, allowing creatives to place each sound exactly where they want it to go, for a more realistic and immersive audio experience.",
      icon: <FaHeadphones />,
      link: "#",
    },
  ];

  return (
    <div className="Services-section">
      <h2 className="Services-heading">The Lab Services</h2>
      <p className="Services-subheading">
        {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo. */}
      </p>
      <div className="Services-grid">
        {services.map((service, index) => (
          <div key={index} className="Service-card">
            <div className="Service-icon">{service.icon}</div>
            <h3 className="Service-title">{service.title}</h3>
            <p className="Service-description">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;