import React, { useEffect, useRef, useState } from "react";
import "./ProjectsSection.css";

// Dynamically import all images from the specified folder
const importAll = (r) => r.keys().map(r);
const covers = importAll(require.context("../../imgs/projects", false, /\.(png|jpe?g|svg)$/));

const ProjectsSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(sectionRef.current); // Stop observing once it’s in view
        }
      },
      {
        threshold: 0.1, // Trigger when 10% of the section is visible
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div ref={sectionRef} className="projects-section">
      <h1 className="headline">Featured Projects</h1>
      <p className="subheadline">Crafted at The Lab Studios, Miami</p>
      <div className="cover-art-container">
        {isVisible &&
          covers.map((cover, index) => (
            <img
              key={index}
              src={cover}
              alt={`Cover ${index + 1}`}
              className="cover-art"
              style={{
                animationDelay: `${Math.random() * 15}s`, // Delay for staggered start
                left: `${Math.random() * 90}%`, // Random position within width
                top: `${Math.random() * -400}px`, // Higher start position
              }}
            />
          ))}
      </div>
    </div>
  );
};

export default ProjectsSection;