import React from "react";
import "./Biography.css";
import bioImage from "../../imgs/echo2.jpg";
import image1 from "../../imgs/the-lab4.jpg"; // Replace with the actual path to your first additional image
import image2 from "../../imgs/StudioB.jpg"; // Replace with the actual path to your second additional image
import image3 from "../../imgs/StudioA.jpg";

function Biography() {
  return (
    <div className="biography-section">
      <div className="biography-container">
        <div className="biography-image">
          <img src={bioImage} alt="Echo at The Lab Studios" />
        </div>
        <div className="biography-content">
          <h1 className="biography-title">Our Journey in the Music Industry</h1>
          <p className="biography-text">
            Echo, an accomplished music producer and businessman, is the visionary behind THE LAB STUDIOS in Miami and San Juan. With state-of-the-art equipment and a team of talented engineers, songwriters, and producers, Echo has helped craft hits and timeless classics for some of the biggest names in the music industry.
          </p>
          <p className="biography-text">
            His recent work includes collaborations with Tego Calderón, De La Ghetto, J King & Maximan, and Pitbull. Known for his dedication to quality and innovation, Echo’s studios are where ideas transform into chart-topping tracks and groundbreaking sounds.
          </p>
        </div>
      </div>
      
      <div className="biography-gallery">
        <div className="gallery-item">
          <img src={image1} alt="Studio Equipment" />

        </div>
        <div className="gallery-item">
          <img src={image2} alt="Echo Collaborating with Artists" />

        </div>
        <div className="gallery-item">
          <img src={image3} alt="Echo at Work" />
        
        </div>
      </div>
    </div>
  );
}

export default Biography;