import React from 'react';
import Services from '../containers/Services/Services';
import Footer from '../containers/Footer/Footer';

  
const ServicesPage = () => {
  return (
    <div>
      <Services />
      <Footer />
    </div>
  );
};
  
export default ServicesPage;