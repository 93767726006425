import React from 'react';
import Projects from '../containers/Discography/ProjectsSection';

const ProjectsPage = () => {
  return (
    <div>
      <Projects />
    </div>
  );
};
  
export default ProjectsPage;