import React from "react";
import { useHistory } from "react-router-dom"; // Import useHistory for older versions of react-router-dom
import "./BioPreviewSection.css";
import previewImage from "../../imgs/the-lab1.jpg"; // Replace with the actual path to your image

function BioPreviewSection() {
  const history = useHistory(); // Initialize the history function

  const handleClick = () => {
    history.push("/aboutus"); // Navigate to the About Us page
  };

  return (
    <div className="bio-preview-section">
      <div className="content">
        <h1 className="title">We're bringing your creative vision to life</h1>
        <p className="description">
          From THE LAB STUDIOS in Miami and San Juan, Echo and his elite team transform ideas into chart-topping music, collaborating with renowned artists to shape the sound of today and tomorrow.
        </p>
        <button className="cta-button" onClick={handleClick}>
          <span>explore more</span>
          <span className="arrow">&rarr;</span>
        </button>
      </div>
      <div className="image-container">
        <img src={previewImage} alt="Creative work in action" />
      </div>
    </div>
  );
}

export default BioPreviewSection;